<template>
  <div class="container">
    <div class="columns is-centered">
      <div class="column is-half">
        <div class="box">
          <div class="container has-text-centered">
            <h1 class="title is-4" style="color: black; margin: 10px">{{question}}</h1>
            <div class="clickable" v-on:click="visible = true" v-if="!visible">
              <hr style="margin: 2px">
              <span style="padding-bottom: 2px">Pokaż odpowiedź</span>
            </div>
            <div v-if="visible">
              <h2 class="subtitle is-5" style="color: black; margin-top: 5px" v-html="answer"></h2>
              <div class="container">
                <h1 class="title is-4" style="color: black; margin:0">Umiesz czy nie?</h1>
                <div class="columns is-centered is-mobile">
                  <div class="column is-half"><span class="material-icons clickable" v-on:click="good" style="font-size: 5em; color: lightgreen">sentiment_satisfied_alt</span></div>
                  <div class="column is-half"><span class="material-icons clickable" v-on:click="bad" style="font-size: 5em; color: lightcoral">mood_bad</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    question: String,
    answer: String
  }, data: function () {
    return {
      visible: false
    }
  }, methods: {
    good() {
      this.$emit('done', 1)
      this.visible = false;
    }, bad() {
      this.$emit('done', 0);
      this.visible = false;
    }
  }, watch: {
    title() {
      if(this.title === undefined) this.$emit('done', -1);
    }
  }
}
</script>

<style scoped>
.clickable{
  cursor:pointer;
  outline:none
}

</style>