<template>
  <section class="hero is-success is-fullheight">
    <div class="hero-body">
      <Card v-bind:question="item.title" v-bind:answer="item.answer" v-on:done="generateNew(); count($event);"></Card>
    </div>
    <div class="hero-foot"><p>{{stats.good}} dobrych odpowiedzi, {{stats.bad}} złych, <a style="color: blue" v-on:click="stats.bad=0; stats.good=0;">walnij reset</a>, <a style="color: blue" href="https://jp2krzeszowice.pl" target="_blank">źródło</a></p></div>
  </section>
</template>

<script>
import Card from "@/components/Card";
import axios from "axios";

export default {
  name: 'Home',
  components: {Card},
  data : function () {
    return {
      item: {},
      list: [],
      stats: {
        good: 0,
        bad: 0
      }
    }
  }, async created() {
    if(localStorage.getItem("stats")) this.stats = JSON.parse(localStorage.getItem("stats"));
    const result = await axios.get("/data.json");
    this.list = result.data;
    this.generateNew();
  }, methods: {
    generateNew() {
      let i;
      this.list.forEach(el => {
        if(el.id === (Math.floor(Math.random() * 111) + 1)) i = el;
      })
      this.item = i;
    }, count(data) {
      console.log(data)
      if(data === 1) this.stats.good++;
      if(data === 0) this.stats.bad++;
      localStorage.setItem('stats', JSON.stringify(this.stats));
    }
  }
}
</script>